<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ isEditPage ? $t('edit') : $t('create') }} {{ $t('programmatic_campaign') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <Button
          class="btn btn-color1 me-2"
          v-if="!isEditPage || (campaign && campaign.status === 'DRAFT')"
          :loading="isSavingDraft"
          @click="saveDraft"
          :disabled="!selectedAdvertiser"
        >
          {{ $t('save_draft') }}
        </Button>
        <Button
          class="btn btn-primary"
          v-b-modal.cancel-campaign-upsert-modal
        >
          {{ $t('cancel') }}
        </Button>
        <CancelCampaignUpsertModal
          :campaign="campaign"
          step="campaign"
        />
      </div>
    </div>
    <!-- CAMPAIGN FORM -->
    <div class="mt-3" v-if="!isEditPage || (isEditPage && campaign)">
      <div class="campaign-wrapper col-lg-8 mx-auto position-relative">
        <ProgramaticCampaignNavigation
          :campaign="campaign"
          step="campaign"
          :innerStep="step"
          v-if="step !== 'init'"
        />
        <!-- INIT STEP -->
        <template v-if="step === 'init'">
          <div class="">
            <div class="card">
              <div class="card-body">
                <p>
                  {{ $t('easy_campaign_pixel_roads') }}
                </p>
                <p>
                  {{ $t('important_thoughts_for_campaign') }}
                </p>
                <ul>
                  <li>{{ $t('campaign_goal') }};</li>
                  <li>{{ $t('campaign_duration') }}</li>
                  <li>{{ $t('available_budget') }}*<br/>
                    <span class="font-size-xSmall">* {{ $t('daily_budget_calculation') }}</span>
                  </li>
                  <li>{{ $t('creatives_in_formats_300x250_300x600_320x450_320x480_336x280_728x90_jpeg_png') }}*<br/>
                    <span class="font-size-xSmall">*{{ $t('required') }}</span>
                  </li>
                  <li>{{ $t('more_about_formats.0') }} <a href="https://pixelroads.com.br/blog/transforme-suas-campanhas-dicas-para-criar-banners-que-convertem/" target="_blank" class="link-color3">{{ $t('more_about_formats.1') }}</a>;</li>
                  <li>{{ $t('target_audience_and_locations') }}</li>
                  <li>{{ $t('suggest_pixel_pre_installed') }}</li>
                </ul>
                <div class="mt-4 text-center">
                  <Button
                    class="btn btn-primary"
                    @click="changeStep('campaign')"
                  >
                    {{ $t('start2') }}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </template> <!-- END INIT STEP -->
        <!-- HEADING -->
        <section class="my-4" v-if="step !== 'init'">
          <div class="text-center">
            <p>
              {{ stepDescription }}
              <span
                v-b-tooltip.hover
                :title="tooltips.pixels"
                v-if="step === 'tracking'"
              >
                <i class="fa fa-info-circle font-size-regular"></i>
              </span>
            </p>
            <div class="mb-3 text-start">
              <a href="#" class="link-color2" @click.prevent="changeStep(previousStep)" v-if="previousStep"><i class="fa fa-angle-left me-2"></i>{{ $t('back') }}</a>
            </div>
          </div>
        </section> <!-- END HEADING -->
        <!-- CAMPAIGN STEP -->
        <template v-if="step === 'campaign'">
          <section class="">
            <div class="mb-5">
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="" class="default-form">
                    <!-- ADVERTISER -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">
                        {{ $t('advertiser') }}*
                        <a
                          href="#"
                          @click.prevent=""
                          v-b-modal.upsert-advertiser-modal
                          v-if="!campaign"
                        >
                          {{ $t('new_advertiser2') }}
                        </a>
                      </label>
                      <div v-if="advertisersLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <template v-else>
                        <div class="custom-select">
                          <select
                            class="form-control"
                            v-model.trim="form.advertiser_id"
                            :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                          >
                            <option disabled="true" selected="true">{{ $t('select') }}</option>
                            <template v-if="advertisers.length > 0">
                              <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
                            </template>
                          </select>
                        </div>
                        <p
                          class="font-size-xSmall fw-bold mt-2 error-message-card"
                          v-if="selectedAdvertiser && selectedAdvertiser.status === 'ERROR'"
                        >
                          {{ $t('advertiser_creation_issue_access_page_try_again', {name:selectedAdvertiser.name}) }}
                        </p>
                      </template>
                    </div> <!-- END ADVERTISER -->
                    <!-- NAME -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('campaign_name') }}*</label>
                      <input type="text" class="form-control" :placeholder="$t('campaign_name')" v-model.trim="form.name" />
                    </div> <!-- END NAME -->
                    <!-- CATEGORY -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('category') }}*</label>
                      <div v-if="categoriesLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <div class="custom-select" v-else>
                        <select
                          class="form-control"
                          v-model.trim="form.category_id"
                          :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                        >
                          <option disabled="true" selected="true">{{ $t('select') }}</option>
                          <template v-if="categories.length > 0">
                            <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ $t('categories.' + category.slug) }}</option>
                          </template>
                        </select>
                      </div>
                    </div> <!-- END CATEGORY -->
                    <!-- SUBCATEGORY -->
                    <div class="form-group mb-3" v-if="selectedCategory && selectedCategory.children.length > 0">
                      <label class="form-label fw-bold">{{ $t('subcategory') }}*</label>
                      <div class="custom-select">
                        <select
                          class="form-control"
                          v-model.trim="form.subcategory_id"
                          :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                        >
                          <option disabled="true" selected="true">{{ $t('select') }}</option>
                          <template v-if="selectedCategory.children.length > 0">
                            <option v-for="(category, index) in selectedCategory.children" :key="index" :value="category.id">{{ category.name }}</option>
                          </template>
                        </select>
                      </div>
                    </div> <!-- END SUBCATEGORY -->
                    <!-- DATES -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('duration') }}*</label>
                      <div class="d-flex">
                        <!-- START DATE -->
                        <div class="col-lg-3">
                          <DateTimeInput
                            v-model.trim="form.start_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('start')"
                            :readonly="campaign && campaign.has_started"
                          />
                        </div> <!-- END START DATE -->
                        <!-- END DATE -->
                        <div class="col-lg-3">
                          <DateTimeInput
                            v-model.trim="form.end_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('end')"
                          />
                        </div> <!-- END END DATE -->
                      </div>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.start_date && !isCampaignStartDateValid"
                      >
                        {{ $t('start_date_not_before_today') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.end_date && !isCampaignEndDateValid"
                      >
                        {{ $t('end_date_validation') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.end_date && isCampaignEndDateBeforePackagesEndDate"
                      >
                        {{ $t('campaign_end_date_not_less_than_campaign_group_end_date') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.start_date && form.end_date && isCampaignEndDateBeforeStartDate"
                      >
                        {{ $t('end_date_not_before_start_date') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 alert-message-card"
                        v-if="form.start_date && form.end_date && isCampaignPeriodBelowRecommended"
                      >
                        {{ $t('campaign_minimum_duration_7_days') }}
                      </p>
                      <CampaignDateUpdateModal
                        :packages="campaign.packages"
                        :selected="form.update_package_ids"
                        @submit="handleCampaignDateUpdateConfirmation"
                        v-if="isEditPage"
                      />
                    </div> <!-- END DATES -->
                    <!-- AMOUNT -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('budget') }}*</label>
                      <Money
                        class="form-control mb-3"
                        v-model.trim="form.amount"
                        v-bind="money"
                      />
                      <template v-if="selectedAdvertiser">
                        <div class="d-flex align-items-center flex-wrap">
                          <div class="col-12 col-lg-7">
                            <p
                              class="font-size-xSmall fw-bold mt-2 error-message-card"
                              v-if="hasCampaignExceededAvailableAdvertiserAmount"
                            >
                              {{ $t('campaign_budget_cannot_exceed') }}
                            </p>
                            <p class="font-size-xSmall fw-bold">
                              {{ $t('available_balance') }}: {{ selectedAdvertiser.available_amount / 100 | formatMoney(selectedAdvertiser.currency) }}
                            </p>
                          </div>
                          <div class="col-12 col-lg-5 text-lg-end d-flex align-items-center justify-content-center justify-content-lg-end">
                            <Button
                              class="btn btn-primary btn-xs me-2"
                              @click="addAllAvailableAmountToCampaign"
                            >
                              {{ $t('use_all_balance') }}
                            </Button>
                            <CreateOrderButton
                              :advertiser="selectedAdvertiser"
                              btnClasses="btn-xs"
                              @order-created="getAdvertisers"
                            />
                          </div>
                        </div>
                      </template>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="!checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
                      >
                        {{ $t('recommended_minimum_daily_budget', {value:minimumDailyBudget, date:this.$options.filters.formatDate(suggestedEndDate)}) }}
                      </p>
                    </div><!-- END AMOUNT -->
                    <!-- ACTION -->
                    <div class="mt-4 text-center">
                      <Button
                        class="btn btn-primary"
                        @click="confirmPackagesAndProceed"
                        :disabled="!isCampaignStepComplete || isSavingDraft || !checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
                      >
                        {{ $t('next') }}
                      </Button>
                    </div> <!-- END ACTION -->
                  </form>
                </div>
              </div>
            </div>
          </section>
          <UpsertAdvertiserModal
            @advertiser-created="getAdvertisers"
          />
        </template> <!-- END CAMPAIGN STEP -->
        <!-- TRACKING STEP -->
        <template v-if="step === 'tracking'">
          <section class="">
            <div class="mb-5">
              <div class="mt-4 text-center">
                <template v-if="form.pixels.length > 0">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>{{ $t('pixel_name') }}</th>
                        <th>{{ $t('rule_name') }}</th>
                        <th>{{ $t('objective') }}</th>
                        <th>{{ $t('event_name') }}</th>
                        <th width="100">{{ $t('actions') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-nowrap" v-for="(rule, index) in form.pixels" :key="index">
                        <td>{{ rule.pixel.name }}</td>
                        <td>{{ rule.name }}</td>
                        <td>{{ rule.objective | formatObjective }}</td>
                        <td>{{ rule.event_name }}</td>
                        <td>
                          <Button
                            class="btn-danger btn-xs font-size-small btn-circle p-0 mb-2"
                            @click.prevent="openDestroyPixelRuleModal(rule)"
                            v-b-tooltip.hover :title="$t('remove_rule')"
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
              <DestroyPixelRuleModal
                :rule="selectedPixelRule"
                @pixel-rule-destroyed="destroyPixelRule"
              />
              <!-- ACTION -->
              <div class="text-center">
                <div>
                  <router-link
                    :to="{ name: 'advertisers.show', params: { id: form.advertiser_id }, query: { tab: 'pixels' } }"
                    class="btn btn-primary mb-4 me-2 btn-sm"
                    target="_blank"
                  >
                    {{ $t('add_new_pixel') }}
                  </router-link>
                  <Button
                    class="btn btn-primary mb-4 btn-sm"
                    v-b-modal.select-pixel-rule-modal
                  >
                    {{ $t('add_existing_pixel') }}
                  </Button>
                  <SelectPixelRuleModal
                    :advertiserId="form.advertiser_id"
                    @pixel-rule-selected="handleSelectedPixelRule"
                  />
                </div>
                <div>
                  <!-- MESSAGES -->
                  <div
                    class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
                    v-if="loader"
                  >
                    <p
                      class="mb-0"
                    >
                      {{ $t('this_action_may_take_a_few_minutes') }}
                    </p>
                  </div> <!-- END MESSAGES -->
                  <Button
                    class="btn btn-primary"
                    @click="clickSubmit"
                    :disabled="!isCampaignStepComplete || isSavingDraft"
                    :loading="loader"
                  >
                    {{ campaign ? $t('save_campaign') : $t('create_campaign') }}
                  </Button>
                </div>
              </div> <!-- END ACTION -->
            </div>
          </section>
        </template> <!-- END TRACKING STEP -->
      </div>
    </div><!-- END CAMPAIGN FORM -->
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import CategoriesService from '@/modules/categories/services/categories-service'
import { getMoneyFormat } from '@/utils'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import CancelCampaignUpsertModal from '@/components/campaigns/CancelCampaignUpsertModal'
import CampaignDateUpdateModal from '@/components/campaigns/Programatic/CampaignDateUpdateModal'
import CreateOrderButton from '@/components/orders/CreateOrderButton'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import DestroyPixelRuleModal from '@/components/campaigns/UpsertForm/DestroyPixelRuleModal'
import Loader from '@/components/common/Loader/Loader'
import { Money } from 'v-money'
import ProgramaticCampaignNavigation from '@/components/campaigns/Programatic/ProgramaticCampaignNavigation'
import SelectPixelRuleModal from '@/components/pixels/SelectPixelRuleModal'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal'

import { mapState } from 'vuex'
import moment from 'moment'

export default {
  mixins: [variables],
  props: ['baseCampaign'],
  components: {
    Button,
    CampaignDateUpdateModal,
    CancelCampaignUpsertModal,
    CreateOrderButton,
    DateTimeInput,
    DestroyPixelRuleModal,
    Loader,
    Money,
    ProgramaticCampaignNavigation,
    SelectPixelRuleModal,
    UpsertAdvertiserModal
  },
  data () {
    return {
      autoSaveInterval: null,
      advertisers: [],
      advertisersLoader: false,
      campaign: null,
      categories: [],
      categoriesLoader: false,
      form: {
        advertiser_id: '',
        name: '',
        category_id: '',
        subcategory_id: '',
        start_date: '',
        end_date: '',
        amount: '',
        pixels: [],
        type: 'PROGRAMATIC',
        update_package_ids: []
      },
      isSavingDraft: false,
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      minimumDailyBudget: 10,
      original_end_date: '',
      original_start_date: '',
      selectedPixelRule: '',
      step: 'init'
    }
  },
  created () {
    if (this.isEditPage) {
      this.step = 'campaign'
      this.getCampaign()
    }
    if (this.user) this.getPageContent()
    this.setAutoSaveInterval()
  },
  beforeDestroy () {
    this.clearAutoSaveInterval()
  },
  watch: {
    user (newUser) {
      this.getPageContent()
    },
    /**
     * Watch for changes on category
     */
    'form.category_id' (newCategory, oldCategory) {
      // If is not the first load of campaign that exists, remove subcategory
      if (oldCategory) {
        this.form.subcategory_id = ''
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * check if daily budget is bigger than minimum daily budget
     */
    checkIfDailyBudgetIsBiggerThanMinimumDailyBudget () {
      if (this.suggestedEndDate && this.form.amount > 0) {
        const startDate = moment(this.form.start_date)
        const endDate = moment(this.form.end_date)
        const campaignDays = endDate.diff(startDate, 'days') + 1
        const totalBudget = campaignDays * this.minimumDailyBudget

        return this.form.amount >= totalBudget
      }
      return true
    },
    /**
     * Check if campaign amount has exceeded what advertiser has available
     */
    hasCampaignExceededAvailableAdvertiserAmount () {
      if (!this.selectedAdvertiser) return true
      // If is new campaign, the only thing that matters is the full available amount
      const amount = this.form.amount * 100
      const available = this.selectedAdvertiser.available_amount
      if (!this.campaign) {
        return amount > available
      }

      // If is campaign that already exists, calculate the difference between new amount and old amount and compare that value to available amount
      const difference = amount - this.campaign.amount
      return difference > available
    },
    /**
     * Is campaign end date valid
     */
    isCampaignEndDateValid () {
      if (!this.form.end_date) return false
      const date = moment(this.form.end_date)
      // Check if is update and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.end_date)
        if (date.isSame(then)) return true
      }
      const tomorrow = moment().startOf('day').add(1, 'day')
      return date.isSameOrAfter(tomorrow)
    },
    /**
     * Is campaign status draft
     */
    isCampaignDraft () {
      return this.campaign.status === 'DRAFT'
    },
    /**
     * Checks if campaign end date is before start date
     */
    isCampaignEndDateBeforeStartDate () {
      if (!this.form.end_date || !this.form.start_date) return true
      const startDate = moment(this.form.start_date)
      const endDate = moment(this.form.end_date)

      return endDate.isSameOrBefore(startDate)
    },
    /**
     * Check if campaign end date is before campaign packages end date
     */
    isCampaignEndDateBeforePackagesEndDate () {
      if (!this.campaign) return false
      let flag = false
      const endDate = moment(this.form.end_date)

      this.campaign.packages.forEach(campaignPackage => {
        const packageEndDate = moment(campaignPackage.end_date)
        if (endDate.isBefore(packageEndDate)) flag = true
      })

      return flag
    },
    /**
     * Check if campaign period is below recommended
     */
    isCampaignPeriodBelowRecommended () {
      if (!this.form.start_date || !this.form.end_date) return true
      const start = moment(this.form.start_date)
      const end = moment(this.form.end_date)
      return end.diff(start, 'days') < 7
    },
    /**
     * Is campaign start date valid
     */
    isCampaignStartDateValid () {
      if (!this.form.start_date) return false
      const date = moment(this.form.start_date)
      // Check if is update of started campaign and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.start_date)
        if (date.isSame(then)) return true
      }
      const today = moment().endOf('day')
      return date.isAfter(today)
    },
    /**
     * Check if campaign is complete
     */
    isCampaignStepComplete () {
      if (
        !this.form.advertiser_id ||
        !this.form.name ||
        !this.form.category_id ||
        !this.form.start_date ||
        !this.form.end_date ||
        parseFloat(this.form.amount) <= 0 ||
        this.hasCampaignExceededAvailableAdvertiserAmount ||
        !this.isCampaignStartDateValid ||
        !this.isCampaignEndDateValid ||
        this.isCampaignEndDateBeforeStartDate ||
        !this.selectedAdvertiser ||
        this.selectedAdvertiser.status === 'ERROR' ||
        this.isCampaignEndDateBeforePackagesEndDate
      ) {
        return false
      }

      // Check for subcategory in case there are any
      if (this.selectedCategory && this.selectedCategory.children.length > 0 && !this.form.subcategory_id) return false

      return true
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'campaigns.edit'
    },
    /**
     * Check if is native campaign
     */
    isNativeCampaign () {
      // If is creation page, get name of route
      if (!this.isEditPage) return this.routeName === 'campaigns.native.create'

      // Check if has campaign and is of correct type
      return this.campaign && this.campaign.type === 'NATIVE'
    },
    /**
     * Check if is programatic campaign
     */
    isProgramaticCampaign () {
      // If is creation page, get name of route
      if (!this.isEditPage) return this.routeName === 'campaigns.programatic.create'

      // Check if has campaign and is of correct type
      return this.campaign && this.campaign.type === 'PROGRAMATIC'
    },
    /**
     * Get previous step
     */
    previousStep () {
      switch (this.step) {
        case 'campaign':
          return null
        case 'tracking':
          return 'campaign'
        default:
          return null
      }
    },
    /**
     * Router name
     */
    routeName () {
      return this.$route.name
    },
    /**
     * Selected advertiser
     */
    selectedAdvertiser () {
      if (!this.form.advertiser_id) return null

      return this.advertisers.find(item => {
        this.money = getMoneyFormat(item.currency)

        return item.id === this.form.advertiser_id
      })
    },
    /**
     * Selected category
     */
    selectedCategory () {
      if (!this.form.category_id) return null

      return this.categories.find(item => {
        return item.id === this.form.category_id
      })
    },
    /**
     * Step description
     */
    stepDescription () {
      switch (this.step) {
        case 'campaign':
          return this.$t('create_basic_campaign_settings')
        case 'tracking':
          return this.$t('configure_tracking_pixels')
        default:
          return ''
      }
    },
    /**
     * calculate campaign end date, with minimum Daily Budget
     */
    suggestedEndDate () {
      if (!this.form.start_date || !this.form.end_date) return null

      const startDate = moment(this.form.start_date)

      const suggestedEndDate = startDate.clone().add(Math.floor(this.form.amount / this.minimumDailyBudget), 'days').subtract(1, 'day')
      return suggestedEndDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }
  },
  methods: {
    /**
     * Add all selected advertiser available amount to campaign
     */
    addAllAvailableAmountToCampaign () {
      if (!this.selectedAdvertiser) return
      // If is creation, use full amount
      if (!this.isEditPage) {
        this.form.amount = (this.selectedAdvertiser.available_amount / 100)
      } else {
        // If is edit page, add the amount to what is in the form
        this.form.amount = this.form.amount + (this.selectedAdvertiser.available_amount / 100)
      }
    },
    /**
     * Change step of form
     */
    changeStep (step) {
      if (step === 'tracking') this.saveDraft()
      this.step = step
    },
    /**
     * Clear auto save interval
     */
    clearAutoSaveInterval () {
      // this.isAutoSaveEnabled = false
      clearInterval(this.autoSaveInterval)
    },
    /**
     * On click submit button
     */
    clickSubmit () {
      this.submit(false)
    },
    /**
     * Check if campaign has more than one package and if is date update to show modal
    */
    confirmPackagesAndProceed () {
      if (!this.baseCampaign) this.changeStep('tracking')
      if ((this.form.start_date !== this.original_start_date) ||
      (this.form.end_date !== this.original_end_date)) {
        this.$bvModal.show('upsert-campaign-date-modal')
      } else {
        this.changeStep('tracking')
      }
    },
    /**
     * Remove pixel rule from rules array
     */
    destroyPixelRule (rule) {
      const index = this.form.pixels.findIndex(item => {
        return item.id === rule.id
      })
      this.form.pixels.splice(index, 1)
    },
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * Get advertiser
     */
    async getCampaign () {
      if (!this.baseCampaign) return
      this.campaign = this.baseCampaign
      this.setCampaign(this.baseCampaign)
    },
    /**
     * Get categories
     */
    async getCategories () {
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({
          campaign_type: 'PROGRAMATIC',
          type: 'CAMPAIGN'
        })
        this.categories = categories.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.categoriesLoader = false
    },
    /**
     * Get necessary page content
     */
    getPageContent () {
      this.getAdvertisers()
      this.getCategories()
    },
    /**
     * Handle campaign date update confirmation
    */
    handleCampaignDateUpdateConfirmation (packages) {
      this.form.update_package_ids = packages
      this.changeStep('tracking')
    },
    /**
     * Handle selected pixel rule
     */
    handleSelectedPixelRule (rule) {
      const index = this.form.pixels.findIndex(item => {
        return item.id === rule.id
      })
      if (index >= 0) return
      this.form.pixels.push(rule)
    },
    /**
     * Open modal to destroy pixel rule
     */
    openDestroyPixelRuleModal (rule) {
      this.selectedPixelRule = rule
      this.$bvModal.show('destroy-pixel-rule-modal')
    },
    /**
     * Save draft
     */
    saveDraft () {
      if (!this.selectedAdvertiser) return
      if (this.campaign && this.campaign.status !== 'DRAFT') return
      this.submit(true)
    },
    /**
     * Create auto save interval
     */
    setAutoSaveInterval () {
      // this.isAutoSaveEnabled = true
      this.autoSaveInterval = setInterval(() => {
        this.saveDraft()
      }, 30000)
    },
    /**
     * Set campaign
     */
    setCampaign (campaign) {
      this.form.advertiser_id = campaign.advertiser.id
      this.form.name = campaign.name
      // Check subcategory
      if (campaign.category.parent) {
        this.form.category_id = campaign.category.parent.id
        this.form.subcategory_id = campaign.category.id
      } else {
        this.form.category_id = campaign.category.id
      }
      this.form.start_date = campaign.start_date
      this.original_start_date = new Date(campaign.start_date).toISOString().split('T')[0] + 'T00:00:00.000-03:00'
      this.form.end_date = campaign.end_date
      this.original_end_date = new Date(campaign.end_date).toISOString().split('T')[0] + 'T00:00:00.000-03:00'
      this.form.amount = campaign.amount / 100
      this.form.pixels = campaign.pixels.map(item => {
        return {
          id: item.id,
          name: item.name,
          objective: item.objective,
          event_name: item.event_name,
          pixel: item.pixel
        }
      })
    },
    /**
     * Submit
     */
    async submit (isDraft = false) {
      this.clearAutoSaveInterval()
      if (isDraft) this.isSavingDraft = true
      else this.loader = true
      try {
        // Create copy of form that can be changed (object assign wont work for multi-layered objects)
        const form = JSON.parse(JSON.stringify(this.form))
        // Parse subcategory
        if (form.subcategory_id) form.category_id = form.subcategory_id
        // Parse amount
        form.amount = parseInt(form.amount * 100)
        form.pixels = this.form.pixels.map(item => {
          return item.id
        })
        form.update_package_ids = this.form.update_package_ids.map(item => {
          return item.code
        })
        let campaign = null
        form.is_draft = isDraft
        if (!this.campaign) {
          campaign = await CampaignsService.createCampaign(form)
        } else {
          campaign = await CampaignsService.updateCampaign(this.campaign.id, form)
        }
        if (!isDraft) {
          this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
        } else {
          this.campaign = campaign
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      if (isDraft) this.isSavingDraft = false
      else this.loader = false
      // Reenable autosave
      this.setAutoSaveInterval()
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .programatic-campaign-navigation {
      position: absolute;
      top: 48px;
      left: -100px;
      z-index: 2;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
</style>
